import { CtaWidget, TextButton, Stack, Heading, Body, HStack, Tag, Show } from 'designsystem';
import { FC, ReactNode } from 'react';
import { UpcomingShowsQuery } from '../../gql/api';
import { format } from 'date-fns';
import { slugifyTitle, useDateFormatter } from 'shared';
import { nl, enGB } from 'date-fns/locale';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

const getMetaDataForShow = (
    show: UpcomingShowsQuery['upcomingCinemaShows'][number],
    locale: string,
    timeFormatter: ReturnType<typeof useDateFormatter>['timeFormatter']
): ReactNode[] => {
    const startDay = format(new Date(show.startOn), 'EE', { locale: locale === 'nl' ? nl : enGB });
    const showTimes = !show.composition
        ? [timeFormatter(new Date(show.startOn), 'TIME-SHORT')]
        : show.composition.shows.map(compositionShow => timeFormatter(new Date(compositionShow.startOn), 'TIME-SHORT'));

    return [
        <Body key="widget-meta-0" fontSize={[1, null, null, 2]} lineHeight={0} fontWeight="l">
            {startDay}
        </Body>,
        <Show key="widget-meta-1" above="m">
            <Body fontSize={2} fontWeight="l">
                {' '}
                •{' '}
            </Body>
        </Show>,
        <Body key="widget-meta-2" fontSize={[1, null, null, 2]} lineHeight={0} fontWeight="l">
            {show.location}
        </Body>,
        <Show key="widget-meta-3" above="m">
            <Body fontSize={2} fontWeight="l">
                {' '}
                •{' '}
            </Body>
        </Show>,
        <HStack key="widget-meta-4">
            {showTimes.map(showTime => (
                <Tag size="s" key={`${show.id}-${showTime}`}>
                    {showTime}
                </Tag>
            ))}
        </HStack>,
    ];
};

interface Props {
    heading: string;
    upcomingShows: UpcomingShowsQuery['upcomingCinemaShows'];
    programUri: string;
}

const CinemaWidget: FC<Props> = ({ heading, upcomingShows, programUri }) => {
    const { timeFormatter } = useDateFormatter();
    const { locale } = useRouter();
    return (
        <CtaWidget
            colorScheme="institute"
            title={heading}
            buttons={[
                <TextButton key={0} href={programUri}>
                    <FormattedMessage id="allevents" defaultMessage="Alle events" />
                </TextButton>,
            ]}
            width="100%"
            position={['static', null, null, 'absolute']}
            top={[0, null, null, 'auto']}
            bottom={0}
            left={0}
            right={0}
        >
            <Stack spacing={4}>
                {upcomingShows?.map(show => (
                    <Link
                        key={show?.id}
                        href={
                            show.composition
                                ? `/composition/${show?.composition?.id}/${slugifyTitle(show.fullTitle)}`
                                : `/cinema/${show?.film?.id}/${slugifyTitle(show.fullTitle)}`
                        }
                    >
                        <Heading variant={4}>{show.fullTitle}</Heading>
                        <Stack direction={['column', null, null, 'row']} spacing={[0, null, null, 1]}>
                            {getMetaDataForShow(show, locale, timeFormatter)}
                        </Stack>
                    </Link>
                ))}
            </Stack>
        </CtaWidget>
    );
};

export default CinemaWidget;
